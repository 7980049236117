<template>
  <details-banner-layout>
    <!-- task icon -->
    <template v-slot:avatar>
      <task-icon :task="task"></task-icon>
    </template>

    <!-- task name -->
    <template v-slot:default>
      {{ title }}
    </template>

    <!-- task subtitle -->
    <template v-slot:subtitle>
      <task-status :task="task"></task-status>
    </template>
  </details-banner-layout>
</template>

<script>
export default {
  name: "TaskBanner",
  components: {
    DetailsBannerLayout: () =>
      import("@/components/shared/core/banners/DetailsBannerLayout"),
    TaskIcon: () => import("@/components/task/TaskIcon"),
    TaskStatus: () => import("@/components/task/TaskStatus")
  },
  props: {
    /**
     * @type {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee:string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole:string, canReassign:boolean, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone:boolean, isOverdue:boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled:boolean, workflowDefinitionId:number, notes: {id:number, taskId:number, text:string, userId:number, userName:string, created:string}[]}}
     */
    task: undefined
  },
  computed: {
    title() {
      return this.task?.name ?? "";
    }
  }
};
</script>
